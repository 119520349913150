import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Socket } from "socket.io-client";
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Picker from "emoji-picker-react";
import { RootState } from "../../redux/store";
import {
  addMessage,
  fetchChatMessages,
  setUserOnline,
} from "../../redux/actions/chatActions";
import { Message, MessageType } from "../../redux/messageTypes";
import s from "./chat-window.module.scss";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface ChatWindowProps {
  socket: Socket | null;
  userId: string;
  userName: string;
  id: string;
  closeChat: () => void;
  isMinimized: boolean;
  toggleMinimize: () => void;
  className?: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  socket,
  userId,
  userName,
  id,
  closeChat,
  isMinimized,
  toggleMinimize,
  className,
}) => {
  const [messageContent, setMessageContent] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const dispatch: ThunkDispatch<RootState, undefined, AnyAction> =
    useDispatch();
  const storedData = sessionStorage.getItem(`${userId}_chatMessages`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const messages = storedData ? JSON.parse(storedData) : [];
  const [initialMessagePosition, setInitialMessagePosition] = useState(
    messages[0]?.timestamp || null
  );
  const userStatus = useSelector(
    (state: RootState) => state.chat.userStatus[userId]
  );

  useEffect(() => {
    if (firstLoad) {
      dispatch(fetchChatMessages(userId, 0, 10));
      setTimeout(handleScrollToBottom, 100);
      setFirstLoad(false);
    }
  }, [dispatch, firstLoad, userId]);

  const handleSendMessage = () => {
    if (messageContent.trim() !== "" && socket) {
      const newMessage: Message = {
        toId: userId,
        fromId: id,
        type: MessageType.MESSAGE,
        timestamp: new Date(),
        data: messageContent,
      };
      updateSessionStorage(userId, newMessage);
      dispatch(addMessage(userId, newMessage));
      socket.emit("message", newMessage);
      dispatch(setUserOnline(id));
      setMessageContent("");
      setTimeout(handleScrollToBottom, 100);
    }
  };

  useEffect(() => {
    socket?.on("message", (newMessage: Message) => {
      updateSessionStorage(userId, newMessage);
      dispatch(addMessage(userId, newMessage));
      setTimeout(handleScrollToBottom, 100);
    });
    return () => {
      socket?.off("message");
    };
  }, [socket, userId, dispatch]);


  const updateSessionStorage = (userId: string, newMessage: Message) => {
    const storedData = sessionStorage.getItem(`${userId}_chatMessages`);
    const messages = storedData ? JSON.parse(storedData) : [];
    sessionStorage.setItem(
      `${userId}_chatMessages`,
      JSON.stringify([...messages, newMessage])
    );
  };
  

  const handleEmojiSelect = (emojiObject: { emoji: string }) => {
    setMessageContent((prev) => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const formatTimestamp = (timestamp: Date) => {
    return timestamp.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleScrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const handleScroll = useCallback(async () => {
    if (messagesContainerRef.current) {
      const { scrollTop, clientHeight } = messagesContainerRef.current;
      const scrolledNearTop = scrollTop <= clientHeight;
      if (scrolledNearTop) {
        const currentMessagePosition = messages[0]?.timestamp;
        if (currentMessagePosition !== initialMessagePosition) {
          dispatch(fetchChatMessages(userId, messages.length, 10));
          setInitialMessagePosition(currentMessagePosition);
        }
      }
    }
  }, [dispatch, messages, userId, initialMessagePosition]);

  useEffect(() => {
    if (!firstLoad) {
      const scrollContainer = messagesContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [dispatch, firstLoad, handleScroll]);

  return (
    <Paper elevation={3} className={s.chatWindow}>
      <AppBar position="static" color="primary" className={s.appBar}>
        <Toolbar variant="dense">
          <div className={s.userStatusIndicator}>
            <div
              className={
                userStatus === "online"
                  ? s.userOnlineIndicator
                  : s.userOfflineIndicator
              }
            />
          </div>
          <Typography variant="h6" color="inherit">
            {userName}
          </Typography>
          <div className={s.rightToolbar}>
            <IconButton onClick={toggleMinimize}>
              <MinimizeIcon />
            </IconButton>
            <IconButton onClick={closeChat}>
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {!isMinimized ? null : (
        <>
          <div className={s.messagesContainer} ref={messagesContainerRef}>
            {messages.map((message: any, index: number) => {
              if (!message || typeof message.data !== "string") {
                console.error("Invalid or no data message:", message);
                return null;
              }
              const messageClass =
                message.fromId === id ? s.messageSent : s.messageReceived;
              return (
                <div key={index} className={`${messageClass}`}>
                  <div className={s.messageContent}>{message.data}</div>
                  {message.timestamp && (
                    <div className={s.messageTimestamp}>
                      {formatTimestamp(new Date(message.timestamp))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <Grid container className={s.inputContainer} alignItems="center">
            <TextField
              fullWidth
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && messageContent.trim() !== "") {
                  handleSendMessage();
                  e.preventDefault();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowEmojiPicker((prev) => !prev)}
                    >
                      <EmojiEmotionsIcon />
                    </IconButton>
                    <IconButton onClick={handleScrollToBottom}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {showEmojiPicker && (
              <div className={s.emojiPickerContainer}>
                <Picker onEmojiClick={handleEmojiSelect} />
              </div>
            )}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default ChatWindow;
