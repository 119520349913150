import { useEffect, useState } from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import s from '../questions-dialog.module.scss';

export const NumberQuestion = ({ saveAnswer, code, answer, questions }: any) => {
  const [value, setValue] = useState(answer || '');
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setValue(answer);
  }, [answer]);

  const onSave = () => {
    setIsBusy(true);
    saveAnswer(code, value || '-', () => {
      setIsBusy(false);
    });
  };

  return (
    <div className={s.answerBlock}>
      <FormControl fullWidth>
        <Typography variant="subtitle1">{questions[code]}</Typography>
        <TextField
          type="number"
          value={value}
          onBlur={onSave}
          onChange={(e) => setValue(e.target.value)}
          disabled={isBusy}
          variant="outlined"
        />
      </FormControl>
    </div>
  );
};
