import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enAppTranslation from './locales/en/app.json';
import esAppTranslation from './locales/es/app.json';

import enMembersTranslation from './locales/en/members.json';
import esMembersTranslation from './locales/es/members.json';

import enPicturesDialogTranslation from './locales/en/pictures-dialog.json';
import esPicturesDialogTranslation from './locales/es/pictures-dialog.json';

import enPaymentMethodForm from './locales/en/payment-method-form.json';
import esPaymentMethodForm from './locales/es/payment-method-form.json';

import enQuestionsDialogTranslation from './locales/en/questions-dialog.json';
import esQuestionsDialogTranslation from './locales/es/questions-dialog.json';

i18n
  // detect user language
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        app: enAppTranslation,
        members: enMembersTranslation,
        picturesDialog: enPicturesDialogTranslation,
        paymentMethodForm: enPaymentMethodForm,
        questionsDialog: enQuestionsDialogTranslation,
      },
      es: {
        app: esAppTranslation,
        members: esMembersTranslation,
        picturesDialog: esPicturesDialogTranslation,
        paymentMethodForm: esPaymentMethodForm,
        questionsDialog: esQuestionsDialogTranslation,
      },
    },
  });

export default i18n;
