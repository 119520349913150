import { useEffect, FC, useState } from "react";
import PaymentForm from "./payment-form/payment-method-form";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { appConfigs } from "../../configs";
import { Stack } from "@mui/material";
import { apiCall } from "../services/api-call.service";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

const stripePromise = loadStripe(appConfigs.stripePublicKey as string);

const Onboarding: FC = () => {
  // Get the user

  const [products, setProducts] = useState<any>(null);
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("at");
    if (!token) {
      window.location.href = "/";
      return;
    }
    const fetchUser = async () => {
      const { sub } = jwtDecode(token as string) as any;
      const user = await apiCall("get", `users/${sub}`);
      if (user?.stripeId) {
        window.location.href = "/";
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const results = await apiCall("get", "stripe/products");
      const onboardingProducts = results?.filter
        ? results
            ?.filter(
              (product: any) =>
                product.metadata?.product_type === "onboarding_option"
            )
            .reverse()
        : [];
      setProducts(onboardingProducts || []);
    };
    fetchProducts();
  }, []);

  return (
    <Stack>
      <Elements stripe={stripePromise}>
        <PaymentForm products={products} />
      </Elements>
    </Stack>
  );
};

export default Onboarding;
