import React from "react";
import { Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

interface OutletProps {
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}

const MaterialTabs: React.FC<OutletProps> = ({ currentTab, setCurrentTab }) => {
  const { t } = useTranslation("members");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Tabs centered indicatorColor="secondary" onChange={handleTabChange}>
      <Tab
        label={t("all-tab")}
        style={{
          color: "white",
          borderBottom: currentTab === 0 ? "3px solid white" : "none",
        }}
      />
      <Tab
        label={t("liked-tab")}
        style={{
          color: "white",
          borderBottom: currentTab === 1 ? "3px solid white" : "none",
        }}
      />
      <Tab
        label={t("liked-me-tab")}
        style={{
          color: "white",
          borderBottom: currentTab === 2 ? "3px solid white" : "none",
        }}
      />
      <Tab
        label={t("matched-tab")}
        style={{
          color: "white",
          borderBottom: currentTab === 3 ? "3px solid white" : "none",
        }}
      />
    </Tabs>
  );
};

export default MaterialTabs;
