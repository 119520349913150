import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  SET_FILTERS,
  getFiltersApi,
  updateFiltersApi,
} from "../../../redux/actions/membersActions";

interface FiltersProps {
  onClose: () => void;
  open: boolean;
}

const Filters: React.FC<FiltersProps> = ({ onClose, open, ...rest }) => {
  const [shouldApplyFilters, setShouldApplyFilters] = useState<boolean>(false);
  const [filtersLoaded, setFiltersLoaded] = useState<boolean>(false);
  const [ageRange, setAgeRange] = useState<number[]>([0, 0]);
  const [miles, setMiles] = useState<number>(20);
  const dispatch = useDispatch();
  const maxMiles = 100;
  const stepAge = 5;
  const maxAge = 100;
  const step = 10;

  useEffect(() => {
    if (!filtersLoaded) {
      getFiltersFromBackend();
    } else if (window.g_user?.age !== undefined) {
      const minAge = Math.max(18, window.g_user?.age - 5);
      const maxAge = Math.min(100, window.g_user?.age + 5);
      setAgeRange([minAge, maxAge]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFiltersFromBackend = async () => {
    try {
      const filters = await dispatch(getFiltersApi() as any);
      dispatch({ type: SET_FILTERS, payload: filters });
      setAgeRange(filters.ageRange as number[]);
      setMiles(filters.miles as number);
      setShouldApplyFilters(filters.shouldApplyFilters as boolean);
    } catch (error) {
      console.error("Error fetching filters from backend:", error);
    } finally {
      setFiltersLoaded(true);
    }
  };

  const marks = [];
  for (let value = 0; value <= maxMiles; value += step) {
    marks.push({ value, label: value.toString() });
  }

  const ageMarks = [];
  for (let value = 0; value <= maxAge; value += stepAge) {
    ageMarks.push({ value, label: value.toString() });
  }

  const updateFilters = (updatedShouldApplyFilters: boolean) => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        ageRange,
        miles,
        shouldApplyFilters: updatedShouldApplyFilters,
      },
    });
    setTimeout(async () => {
      dispatch(
        updateFiltersApi(ageRange, miles, updatedShouldApplyFilters) as any
      );
    }, 1000);
  };

  const handleAgeChange = (event: Event, newValue: number | number[]) => {
    setAgeRange(newValue as number[]);
    setShouldApplyFilters(true);
    handleSliderMouseUp();
  };

  const handleMilesChange = (event: Event, newValue: number | number[]) => {
    setMiles(newValue as number);
    setShouldApplyFilters(true);
    handleSliderMouseUp();
  };

  const handleSwitchChange = () => {
    const updatedValue = !shouldApplyFilters;
    setShouldApplyFilters(updatedValue);
    updateFilters(updatedValue);
  };

  const handleSliderMouseUp = () => {
    if (shouldApplyFilters) {
      updateFilters(shouldApplyFilters);
    }
  };

  return open ? (
    <Box
      {...rest}
      sx={{
        width: "100%",
        backgroundColor: "#1976d2",
        padding: 2,
        color: "#fff",
        position: "fixed",
        top: 56,
        zIndex: 1000,
        transition: "top 0.3s ease",
      }}
    >
      <Typography variant="h4">Filters</Typography>

      <Grid container spacing={4}>
        {/* Primera columna */}
        <Grid item xs={4}>
          {/* Filtro de Rango de Edad */}
          <Typography id="age-slider" gutterBottom>
            Age range
          </Typography>
          <Box sx={{ position: "relative" }}>
            <Slider
              value={ageRange}
              onChange={handleAgeChange}
              onChangeCommitted={handleSliderMouseUp}
              valueLabelDisplay="auto"
              marks={ageMarks}
              min={20}
              max={maxAge}
              getAriaValueText={(value) => `${value} age`}
              sx={{
                color: "white",
                "& .MuiSlider-valueLabel": {
                  color: "white",
                },
                "& .MuiSlider-markLabel": {
                  color: "white",
                },
                "& .MuiSlider-mark": {
                  "&::before": {
                    backgroundColor: "white",
                  },
                },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: -40,
                left: `${
                  ((window.g_user?.age !== undefined ? window.g_user?.age : 0) /
                    maxAge) *
                  100
                }%`,
                transform: "translateX(-50%)",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ color: "white", fontSize: "16px", fontWeight: "bold" }}
              >
                {window.g_user?.age !== undefined ? window.g_user?.age : 0} años
              </Typography>
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderTop: "8px solid white",
                  borderLeft: "6px solid transparent",
                  borderRight: "6px solid transparent",
                  margin: "8px auto 0",
                }}
              />
            </Box>
          </Box>
        </Grid>

        {/* Separador vertical */}
        <Grid item xs={1}>
          <div
            style={{
              height: "100%",
              borderRight: "1px solid white",
              margin: "0 10px",
            }}
          />
        </Grid>

        {/* Segunda columna */}
        <Grid item xs={4}>
          {/* Filtro de Millas */}
          <Typography id="miles-slider" gutterBottom>
            Miles
          </Typography>
          <Slider
            aria-label="Filters of Miles"
            value={miles}
            onChange={handleMilesChange}
            onChangeCommitted={handleSliderMouseUp}
            step={step}
            valueLabelDisplay="auto"
            marks={marks}
            min={0}
            max={maxMiles}
            getAriaValueText={(value) => `${value} miles`}
            sx={{
              color: "white",
              "& .MuiSlider-valueLabel": {
                color: "white",
              },
              "& .MuiSlider-markLabel": {
                color: "white",
              },
              "& .MuiSlider-mark": {
                "&::before": {
                  backgroundColor: "white",
                },
              },
            }}
          />
        </Grid>

        {/* Tercera columna */}
        <Grid item xs={2}>
          <Switch
            checked={shouldApplyFilters}
            onChange={handleSwitchChange}
            name="loading"
            color="primary"
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#fff",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "#fa0202",
              },
              "& .MuiSwitch-thumb": {
                transform: "scale(1.5)",
              },
              "& .MuiSwitch-track": {
                transform: "scale(1.5)",
              },
            }}
          />
        </Grid>
      </Grid>

      <IconButton
        color="inherit"
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1001,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  ) : null;
};

export default Filters;
