export const SignupStringsEnUs = {
  title: 'Sign up',
  titleWithGoogle: 'Sign up with your Google Account',

  name: 'Name',
  namePlaceholder: 'Dan',

  password: 'Password',
  passwordPlaceholder: 'mySecret01*',

  email: 'Email',
  emailPlaceholder: 'me@testemail.com',
  birthdate: 'Birth Date',
  birthdatePlaceholder: '09/10/1985',

  gender: 'Gender',
  seeking: 'Seeking',
  country: 'Country',
  countryPlaceholder: 'US',

  cityOrZip: 'City or Zip',
  cityOrZipPlaceHolder: 'Provo',

  male: 'Man',
  female: 'Woman',
  other: 'Other',

  btnSignUp: 'Sign Up',
  btnCancel: 'Cancel',
};
