import React, { useCallback, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiCall } from "../services/api-call.service";
import { IMember } from "../member/member.module";
import s from "./member-preview-dialog.module.scss";
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import femaleSilhouette from "../images/female.png";
import maleSilhouette from "../images/male.png";
import { Close as CloseIcon } from "@mui/icons-material";
import { Favorite as FavoriteIcon } from "@mui/icons-material";
import { ChatBubble as ChatBubbleIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateMemberStatus } from "../../redux/actions/membersActions";
import { openChat } from "../../redux/actions/chatActions";
import ImageScroller from "./picture-carousel.module";

interface IQuestion {
  q: string;
  o: string[];
}

interface MemberPreviewDialogProps {
  id: string;
  setMemberId?: (id: string | null) => void;
  open: boolean;
  onClose?: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MemberPreviewDialog: React.FC<MemberPreviewDialogProps> = ({
  id,
  setMemberId,
  open,
  onClose,
}) => {
  const [data, setData] = useState<IMember | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [value, setValue] = React.useState(0);
  const [questions, setQuestions] = useState<{ [code: string]: IQuestion }>({});
  const [pictures, setPictures] = useState<any[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  let statusColor: "default" | "primary" | "secondary" | "error" = "default";
  switch (status) {
    case "like":
      statusColor = "primary";
      break;
    case "liked":
      statusColor = "secondary";
      break;
    case "mutual":
      statusColor = "error";
      break;
    default:
      statusColor = "default";
      break;
  }
  useEffect(() => {
    apiCall("get", `questions/all/${g_lang}`).then((res) => {
      const questCodes = Object.keys(res);
      // Set questions as an object with code as keys and IQuestion as values
      const questionsObject: { [code: string]: IQuestion } = {};
      questCodes.forEach((code) => {
        questionsObject[code] = res[code];
      });
      setQuestions(questionsObject);
    });
  }, []);

  useEffect(() => {
    if (open) {
      setMemberId?.(id);
      const fetchMember = async () => {
        const res = await apiCall("get", `users/${id}`);
        if (!res) {
          setData(null);
          return;
        }

        setStatus(res.status);
        setData(res);
      };
      fetchMember();
    }
  }, [open, id, setMemberId]);

  useEffect(() => {
    if (data?.id) {
      const fetchPictures = async () => {
        const res = await apiCall("get", `pictures/${data?.id}/list`);
        if (res) {
          setPictures(res);
        }
      };
      fetchPictures();
    }
  }, [data?.id]);

  const handleClose = useCallback(() => {
    setMemberId?.(null);
    onClose?.();
  }, [onClose, setMemberId]);

  const handleLikeClick = useCallback(async () => {
    const res = await apiCall("put", `connections/${data?.id}`);
    if (res.success && data?.id) {
      setStatus(res.status);
      dispatch(updateMemberStatus(data?.id, res.status));
    }
  }, [data?.id, dispatch]);

  const handleChatClick = () => {
    if (data?.id) dispatch(openChat(data?.id, data?.name));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src =
      data?.gender === "f" ? femaleSilhouette : maleSilhouette;
  };

  const imageSrc = `${g_baseUrl}pictures/profile/${data?.id}`;

  return (
    <>
      {data ? (
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {data?.name} - {data?.age}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", minHeight: 475 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Answers" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className={s.pic}>
                  {pictures.length > 0 ? (
                    <ImageScroller pictures={pictures} />
                  ) : (
                    <Card>
                      <CardMedia
                        component="img"
                        sx={{
                          height: "50%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        loading="lazy"
                        image={
                          imageSrc ||
                          (data?.gender === "f"
                            ? femaleSilhouette
                            : maleSilhouette)
                        }
                        alt="User"
                        onError={handleImageError}
                      />
                    </Card>
                  )}
                  {/* </Slider> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {!data.answers ? (
                  <Typography variant="h6">
                    This member has not answered any questions yet
                  </Typography>
                ) : (
                  <Box
                    maxHeight={400}
                    sx={{
                      overflowY: "scroll",
                    }}
                  >
                    {Object.entries(data.answers).map(([key, value], index) => (
                      <Box key={key}>
                        <Box display="flex" alignItems={"center"} gap={2}>
                          <Typography variant="h6">
                            {questions[key]?.q || questions[key]}
                          </Typography>
                          <Typography variant="body1">
                            {questions[key]?.o?.[value] ||
                              (Array.isArray(value)
                                ? value.join(", ")
                                : value)}{" "}
                          </Typography>
                        </Box>
                        {index !== Object.entries(data.answers).length - 1 && (
                          <Divider sx={{ my: 2 }} />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </CustomTabPanel>
            </Box>

            <CardActions
              disableSpacing
              sx={{
                display: "flex",
                justifyContent: "space-around",
                padding: "0",
              }}
            >
              <IconButton
                aria-label="add to favorites"
                color={statusColor}
                onClick={handleLikeClick}
                size={isMobile ? "small" : "medium"}
              >
                <FavoriteIcon />
              </IconButton>
              <IconButton
                aria-label="chat"
                onClick={handleChatClick}
                size={isMobile ? "small" : "medium"}
              >
                <ChatBubbleIcon />
              </IconButton>
            </CardActions>
            {/* Asegúrate de agregar estilos para s.likeButton en tu archivo SCSS */}
            {/* ...resto del contenido... */}
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};

export default MemberPreviewDialog;
