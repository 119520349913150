import "./App.scss";
import Cookies from "universal-cookie";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import s from "./App.module.scss";
import useExternalScripts from "../services/use-external-scripts";
import { apiCall } from "../services/api-call.service";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation("app");
  const cookies = new Cookies();
  const accessToken = cookies.get("at");

  if (accessToken) {
    window.location.href = "/members";
  }

  useExternalScripts("https://accounts.google.com/gsi/client");

  const impersonateUser = async () => {
    const value = (
      document.getElementById("impersonate-email") as HTMLInputElement
    ).value;
    // is it an email?
    const url =
      value.indexOf("@") !== -1
        ? `auth/impersonate/${value}?withToken=true`
        : `auth/impersonate-id/${value}?withToken=true`;
    const res = await apiCall("get", url);
    cookies.set("at", res.accessToken, {
      domain:
        window.location.origin.indexOf("localhost") !== -1
          ? "localhost"
          : ".susurro.com",
    });
    window.location.href = "/members";
  };

  return (
    <div className={s.container}>
      <div className={s.buttons}>
        <div
          id="g_id_onload"
          data-client_id="99309919098-h8u1hgueojkv1tkb6l0gvna3lpgpl0ro.apps.googleusercontent.com"
          data-login_uri={`${g_baseUrl}google/login`}
          data-auto_prompt="false"
        ></div>
        <div
          className="g_id_signin"
          data-type="standard"
          data-auto_select="true"
          data-size="large"
          data-theme="outline"
          data-text="sign_in_with"
          data-shape="rectangular"
          data-logo_alignment="left"
        ></div>
        <input id="impersonate-email" />
        <button onClick={impersonateUser}>Login</button>
      </div>
    </div>
  );
}

export default App;
