import {
  OPEN_CHAT,
  CLOSE_CHAT,
  TOGGLE_MINIMIZE_CHAT,
  ADD_MESSAGE,
  RECEIVE_MESSAGE,
  ChatActionTypes,
  SET_USER_ONLINE,
  SET_USER_OFFLINE,
  FETCH_CHAT_MESSAGES,
  FETCH_CHAT_MESSAGES_FAILED,
} from "../actions/chatActions";
import { Message } from "../messageTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Chat {
  userName: string;
  messages: Message[];
  isMinimized: boolean;
  countNextMessage?: number;
}

interface ChatState {
  chats: { [chatId: string]: Chat };
  userStatus: { [userId: string]: "online" | "offline" };
  activeChat: string | null;
}

const initialState: ChatState = {
  chats: {},
  userStatus: {},
  activeChat: null,
};

function getMemberNameById(fromId: string): string | null {
  const allMembersString = sessionStorage.getItem("allMembers");
  if (allMembersString === null) {
    return null;
  }

  const allMembers = JSON.parse(allMembersString) as Array<{
    id: string;
    name: string;
  }>;
  const member = allMembers.find(
    (member: { id: string; name: string }) => member.id === fromId
  );
  return member ? member.name : null;
}

const chatReducer = (
  state = initialState,
  action: ChatActionTypes
): ChatState => {
  switch (action.type) {
    case OPEN_CHAT:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chatId]: {
            userName: action.payload.userName,
            messages: [],
            isMinimized: true,
          },
        },
      };
    case CLOSE_CHAT:
      const { [action.payload]: _, ...remainingChats } = state.chats;
      return {
        ...state,
        chats: remainingChats,
      };
    case TOGGLE_MINIMIZE_CHAT: {
      const chatId = action.payload;
      const currentChat = state.chats[chatId];

      return {
        ...state,
        chats: {
          ...state.chats,
          [chatId]: {
            ...currentChat,
            isMinimized: !currentChat.isMinimized,
          },
        },
        activeChat: state.activeChat === chatId ? null : chatId,
      };
    }

    case ADD_MESSAGE: {
      const { chatId, message } = action.payload;
      const updatedChat = state.chats[chatId] || {
        userName: getMemberNameById(message.fromId) || "Usuario Desconocido",
        messages: [],
        isMinimized: false,
      };

      return {
        ...state,
        chats: {
          ...state.chats,
          [chatId]: {
            ...updatedChat,
            messages: [...updatedChat.messages, message],
          },
        },
      };
    }

    case RECEIVE_MESSAGE: {
      const { message, userId } = action.payload;
      const chatId = message.fromId === userId ? message.toId : message.fromId;
      const senderName =
        getMemberNameById(message.fromId) || "Usuario Desconocido";

      const existingChat = state.chats[chatId] || {
        userName: senderName,
        messages: [],
        isMinimized: false,
      };

      // Verificar si el chat está minimizado o abierto
      const isChatMinimized = existingChat.isMinimized;
      const isChatOpen = state.activeChat === chatId;

      // Mostrar notificación solo si el chat está cerrado y no minimizado
      if (!isChatOpen && !isChatMinimized) {
        const notification = toast.info(
          `${senderName} envió un nuevo mensaje`,
          {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );

        // Cerrar la notificación automáticamente después de 3 segundos
        setTimeout(() => {
          toast.dismiss(notification);
        }, 3000);
      }

      return {
        ...state,
        chats: {
          ...state.chats,
          [chatId]: {
            ...existingChat,
            messages: [...existingChat.messages, message],
          },
        },
      };
    }
    case SET_USER_ONLINE:
      const updatedOnlineStatus = {
        ...state.userStatus,
        [action.payload]: "online" as "online" | "offline",
      };
      return {
        ...state,
        userStatus: updatedOnlineStatus,
      };
    case SET_USER_OFFLINE:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          [action.payload]: "offline",
        },
      };
    case FETCH_CHAT_MESSAGES: {
      const { userId, messages } = action.payload;
      const { chats } = state;
      const currentChat = chats[userId];

      return {
        ...state,
        chats: {
          ...chats,
          [userId]: {
            ...currentChat,
            messages: [...currentChat.messages, ...messages],
          },
        },
      };
    }
    case FETCH_CHAT_MESSAGES_FAILED: {
      toast.error('Error al recuperar mensajes del chat. Inténtalo de nuevo más tarde.');
      return state;
    }



    default:
      return state;
  }
};

export default chatReducer;
