import { useCallback, useEffect, useState } from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import s from "../questions-dialog.module.scss";

export const TextQuestion = ({ saveAnswer, code, answer, questions }: any) => {
  const [value, setValue] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setValue(answer);
  }, [answer]);

  const onSave = (event: any) => {
    setIsBusy(true);
    saveAnswer(code, value || "-", () => {
      setIsBusy(false);
    });
  };
  const handleChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  return (
    <div className={s.answerBlock}>
      <FormControl fullWidth>
        <Typography variant="subtitle1">{questions[code]}</Typography>
        <TextField
          type="text"
          value={value}
          onBlur={onSave}
          onChange={handleChange}
          disabled={isBusy}
          variant="outlined"
          placeholder="Type your answer here"
        />
      </FormControl>
    </div>
  );
};
