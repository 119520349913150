import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { Action } from "redux";
import { IMember } from "../../components/member/member.module";
import {
  apiCall,
  setToSessionStorage,
  getFromSessionStorage,
} from "../../components/services/api-call.service";

export const SET_ALL_MEMBERS = "SET_ALL_MEMBERS";
export const SET_LIKED_MEMBERS = "SET_LIKED_MEMBERS";
export const SET_MUTUAL_MEMBERS = "SET_MUTUAL_MEMBERS";
export const FETCH_MEMBERS_FAILED = "FETCH_MEMBERS_FAILED";
export const SET_GIVEN_LIKES = "SET_GIVEN_LIKES";
export const UPDATE_MEMBER_STATUS = "UPDATE_MEMBER_STATUS";
export const SET_FILTERS = "SET_FILTERS";


export const setAllMembers = (members: IMember[]) => ({
  type: SET_ALL_MEMBERS,
  payload: members,
});

export const setLikedMembers = (members: IMember[]) => ({
  type: SET_LIKED_MEMBERS,
  payload: members,
});

export const setMutualMembers = (members: IMember[]) => ({
  type: SET_MUTUAL_MEMBERS,
  payload: members,
});

export const fetchMembersFailed = (error: string) => ({
  type: FETCH_MEMBERS_FAILED,
  payload: error,
});

export const setGivenLikes = (members: IMember[]) => ({
  type: SET_GIVEN_LIKES,
  payload: members,
});

export const updateMemberStatus = (memberId: string, status: string) => {
  return {
    type: UPDATE_MEMBER_STATUS,
    payload: { memberId, status },
  };
};

export const updateFiltersApi = (
  ageRange: number[],
  miles: number,
  shouldApplyFilters: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async () => {
    try {
      const apiUrl = "users/filter";

      const data = {
        ageRange,
        miles,
        shouldApplyFilters,
      };

      const res = await apiCall("PUT", apiUrl, data);

      if (res.status === 204) {
        console.info("The operation was successful, but there is no content to return.");
      } else {
        console.info("Successful operation:", res);
      }

    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.error("Error of authentication:", error.response.data);
      } else {
        console.error("Error updating filters 2:", error);
      }
    }
  };
};

export const getFiltersApi = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async () => {
    try {
      const apiUrl = "users/filter";
      const res = await apiCall("GET", apiUrl);

      if (res.error) {
        console.error("API call to get filters failed:", res.error);
      } else {
        return res;
      }

    } catch (error) {
      console.error("Error getting filters:", error);
    }
  };
};

export const fetchMembersByStatus = (
  status: string,
  pageSize: number,
  currentPage: number
): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch, getState) => {
    try {
      const start = (currentPage - 1) * pageSize;
      const end = start + pageSize;

      const storedData = getFromSessionStorage(`${status}Members`) || "";
      const decodedData = storedData ? JSON.parse(atob(storedData)) : [];

      let membersData: IMember[] = [];
      if (decodedData.length < end) {
        membersData = await apiCall(
          "GET",
          `users?status=${status}&start=${currentPage}&limit=${pageSize}`
        );
      }

      // Filtrar y categorizar los miembros antes de guardar en el almacenamiento local
      const filteredMembers = membersData.filter(
        (member) => member.status === status || status === "all"
      );
      const uniqueMembers = Array.from(
        new Set([
          ...decodedData.map((m: IMember) => m.id),
          ...filteredMembers.map((m: IMember) => m.id),
        ])
      ).map((id) =>
        decodedData.concat(filteredMembers).find((m: IMember) => m.id === id)
      );

      setToSessionStorage(`${status}Members`, uniqueMembers);

      switch (status) {
        case "liked":
          dispatch(setLikedMembers(uniqueMembers.slice(start, end)));
          break;
        case "mutual":
          dispatch(setMutualMembers(uniqueMembers.slice(start, end)));
          break;
        case "like":
          dispatch(setGivenLikes(uniqueMembers.slice(start, end)));
          break;
        default:
          dispatch(setAllMembers(uniqueMembers.slice(start, end)));
          break;
      }
    } catch (error: any) {
      dispatch(fetchMembersFailed(error.message));
    }
  };
};
