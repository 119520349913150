import { FC, SyntheticEvent, useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { apiCall } from "../../services/api-call.service";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { StarOutline } from "@mui/icons-material";

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Roboto", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  disableLink: true,
};

interface PaymentMethodFormProps {
  products: any[];
}

const PaymentMethodForm: FC<PaymentMethodFormProps> = ({ products }) => {
  const { t } = useTranslation("payment-method-form");
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const cookies = new Cookies();
  const accessToken = cookies.get("at");
  const token = jwtDecode(accessToken as string) as any;
  const userId = token.sub;
  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const customer = await apiCall("POST", "stripe/customers");
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement as any,
          billing_details: {
            name: customer.name,
          },
        })
        // Callback blackhole. Switch to async/await in the future?
        .then((result) => {
          if (result.paymentMethod) {
            apiCall("POST", `stripe/customers/payment-methods/attach`, {
              paymentMethodId: result.paymentMethod.id,
            }).then((result) => {
              apiCall("PUT", `users/${userId}`, {
                stripeCustomerId: customer.id,
              }).then((result) => {
                apiCall("POST", `stripe/subscriptions`, {
                  priceId: selectedId,
                }).then((result) => {
                  // Get new access token
                  apiCall("GET", `auth/refresh`).then((result) => {
                    cookies.set("at", result.accessToken, { path: "/" });
                    window.location.href = "/";
                  });
                  window.location.href = "/";
                });
              });
            });
          }
        })
        .catch((error) => {
          console.info(error);
        });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={"100vw"}
      position={"absolute"}
      height={"100vh"}
    >
      <Stack spacing={4}>
        {products && (
          <Box display={"flex"} justifyContent={"center"} gap={2}>
            {products.map((product: any, index: number) => (
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 250,
                }}
                key={index}
              >
                <CardHeader
                  title={product.name}
                  titleTypographyProps={{ align: "center" }}
                  action={product.name === "Premium" ? <StarOutline /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme: Theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      color="text.primary"
                    >
                      {(product?.prices[0].unit_amount / 100).toFixed(2)}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <Typography variant="subtitle1" align="center">
                    {product?.description}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <ul>
                    {product?.metadata?.details &&
                      JSON.parse(product.metadata.details).map(
                        (line: string, index: number) => (
                          <Typography
                            component="li"
                            variant="body2"
                            align="center"
                            key={index}
                          >
                            {line}
                          </Typography>
                        )
                      )}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => {
                      setSelectedId(product.prices[0].id);
                    }}
                    fullWidth
                    variant={
                      selectedId === product.prices[0].id ? "contained" : "outlined"
                    }
                  >
                    {selectedId === product.prices[0].id ? t("selected") : t("select")}
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        )}
        <Box display={"flex"} justifyContent={"center"}>
          <form onSubmit={handleSubmit}>
            <Card
              variant="outlined"
              sx={{
                maxWidth: 500,
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                 {t("payment-method-title")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {t("payment-method-form")}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <CardElement
                  options={{
                    ...cardStyle,
                    disabled: selectedId === null,
                  }}
                />

                <Box display={"flex"} justifyContent={"space-between"} mt={5}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!stripe || !elements || selectedId === null}
                    size="small"
                  >
                    {t("pay-and-continue")}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default PaymentMethodForm;
