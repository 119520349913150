import Cookies from 'universal-cookie';
import { SignupStringsEnUs as str } from './signup.strings-en-us';
import s from './signup.module.scss';
import { useState } from 'react';

export const SignUp = () => {
  const [useLocation, setUseLocation] = useState(false);
  const [locationInfo, setLocationInfo] = useState('');
  const [locationId, setLocationId] = useState('');

  const cookies = new Cookies();
  const googleUser = cookies.get('gu');

  const onSignUp = (event: any) => {
    const accessToken = cookies.get('at');

    event.preventDefault();
    const form: any = document.querySelector('form');
    const formData = Object.values(form).reduce((obj: any, field: any) => {
      if (field.name)
        obj[field.name] = field.value;
      return obj
    }, {});

    fetch(g_baseUrl + 'auth/signup/complete', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then(res => {
      window.location.href = '/members';
    }).catch(error => {
      alert(error);
    });
  };

  const onCancel = () => {
    window.location.href = '/';
  };

  const onLocationChange = () => {
    if (!useLocation) {
      setLocationInfo("Loading...");
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        fetch(g_baseUrl + `locations/nearby/${coords.longitude}/${coords.latitude}`)
          .then(res => res.json()).then(res => {
            setLocationInfo(res.city + ", " + res.state);
            setLocationId(res.id);
          });
      })
    }
    setUseLocation(!useLocation);
  };

  return (
    <div className={s.container}>
      <div className={s.title}>
        {googleUser !== undefined ? str.titleWithGoogle : str.title}
      </div>

      <form id="signup" onSubmit={onSignUp}>
        <div className={s.item}>
          <label>{str.birthdate}</label>
          <input name="dob" type="text" placeholder={str.birthdate} />
        </div>

        <div className={s.item}>
          <label>{str.gender}</label>
          <select name="gender">
            <option value="m">{str.male}</option>
            <option value="f">{str.female}</option>
            <option value="o">{str.other}</option>
          </select>
        </div>

        <div className={s.item}>
          <label>{str.seeking}</label>
          <select name="seeking">
            <option value="m">{str.male}</option>
            <option value="f" selected>{str.female}</option>
            <option value="o">{str.other}</option>
          </select>
        </div>

        <div className={s.item}>
          <label>{str.cityOrZip}</label>
          <input name="city" type="text" placeholder={str.cityOrZipPlaceHolder} value={locationInfo} disabled={useLocation} />

          <input type="checkbox" id="useLoc" name="useLoc" onChange={onLocationChange} checked={useLocation} />
          <label className={s.locLabel} htmlFor={'useLoc'}>Use<br />browser<br />location</label>
        </div>

        <input type="hidden" value="US" />
        <input type="hidden" name="locId" value={locationId} />

        <div className={s.buttons}>
          <button type="submit">{str.btnSignUp}</button>
          <button onClick={onCancel}>{str.btnCancel}</button>
        </div>
      </form>
    </div>
  )
};
