import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export const SingleQuestion = ({
  saveAnswer,
  code,
  answer,
  questions,
}: any) => {
  const [myAnswer, setMyAnswer] = useState(answer || '');
  const [isBusy, setIsBusy] = useState(false);
  const answers = questions[code]?.o;

  useEffect(() => {
    setMyAnswer(answer);
  }, [answer]);

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const a = event.target.value;
    setIsBusy(true);
    saveAnswer(code, a, () => {
      setMyAnswer(a);
      setIsBusy(false);
    });
  };

  return (
    <Box
      sx={
        {
          /* Add your styles here */
        }
      }
    >
      <FormControl component="fieldset" disabled={isBusy}>
        <RadioGroup name={code} value={myAnswer} onChange={onSelect}>
          {Object.keys(answers).map((key, i) => {
            const answerStr = questions[code]?.o[key];
            return (
              <FormControlLabel
                key={i}
                value={key}
                control={<Radio />}
                label={answerStr}
                disabled={isBusy}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
