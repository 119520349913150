import React, { FC, useRef } from "react";
import { Box, IconButton, Card, CardMedia } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ImageScrollerProps {
  pictures: any[];
}

const ImageScroller: FC<ImageScrollerProps> = ({ pictures }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scroll = (scrollOffset: number) => {
    // Directly manipulate the scrollLeft property of the current ref if it's not null
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <Box display="flex" alignItems="center" maxWidth="100%" role="presentation">
      <IconButton onClick={() => scroll(-200)} role="button">
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexGrow={1}
        overflow="auto"
        ref={scrollRef}
        gap={2}
        sx={{
          scrollbarWidth: "none", // For Firefox
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Opera
          },
          flexWrap: "nowrap", // Prevent cards from wrapping
        }}
      >
        {pictures?.map((picture, index) => (
          <Card key={index} sx={{ minWidth: 'fit-content', height: 300 }}>
            {" "}
            {/* Adjust minWidth as needed */}
            <CardMedia
              component="img"
              image={`${g_baseUrl}pictures/${picture._id}/url`}
              alt={picture.filename}
              sx={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </Card>
        ))}
      </Box>
      <IconButton onClick={() => scroll(200)}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default ImageScroller;
