import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { apiCall } from "../services/api-call.service";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

import amex from "../../assets/cc-icons/american-express.png";
import discover from "../../assets/cc-icons/discover-alt.png";
import mastercard from "../../assets/cc-icons/mastercard.png";
import visa from "../../assets/cc-icons/visa-alt.png";
import PaymentMethodUpdateDialog from "./payment-method-update-dialog.module";
import { loadStripe } from "@stripe/stripe-js";
import { appConfigs } from "../../configs";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

interface BillingDialogProps {
  open: boolean;
  onClose: () => void;
}

const stripePromise = loadStripe(appConfigs.stripePublicKey);

const BillingDialog: FC<BillingDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation("payment-method-form");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState("");
  const [paymentMethodUpdateDialogOpen, setPaymentMethodUpdateDialogOpen] =
    useState<boolean>(false);
  const cookies = new Cookies();
  const token = cookies.get("at");
  const { sub } = jwtDecode(token as string) as any;

  const unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const user = await apiCall("get", `users/${sub}`);
        if (!user?.stripeId) {
          window.location.href = "/onboarding";
          return;
        }
        const response = await apiCall(
          "get",
          `stripe/customers/payment-methods`
        );
        if (response) {
          setDefaultPaymentMethod(response.default?.id);
          setData(response.data);
          setLoading(false);
        } else {
          console.error("The API response is null or undefined.");
          setError(true);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error while getting API data:", error);
        setError(true);
        setLoading(false);
      }
    };
    fetchPaymentMethods();
  }, [token, sub]);

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const user = await apiCall("get", `users/${sub}`);
      if (!user?.stripeId) {
        window.location.href = "/onboarding";
        return;
      }

      const response = await apiCall("get", `stripe/customers/payment-methods`);
      if (response) {
        // Verificar si el componente aún está montado antes de actualizar el estado
        if (!unmounted.current) {
          setDefaultPaymentMethod(response.default?.id);
          setData(response.data);
          setLoading(false);
        }
      } else {
        console.error("The API response is null or undefined.");
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error retrieving api data:", error);
      // Verificar si el componente aún está montado antes de actualizar el estado
      if (!unmounted.current) {
        setError(true);
        setLoading(false);
      }
    }
  }, [sub]);

  const renderCardBrand = (brand: string) => {
    const size = 40;
    if (brand === "visa") {
      return <img height={size} src={visa} alt="Visa" />;
    }
    if (brand === "mastercard") {
      return <img height={size} src={mastercard} alt="Mastercard" />;
    }
    if (brand === "amex") {
      return <img height={size} src={amex} alt="American Express" />;
    }
    if (brand === "discover") {
      return <img height={size} src={discover} alt="Discover" />;
    }
  };

  const handlePaymentUpdate = useCallback(() => {
    setPaymentMethodUpdateDialogOpen(true);
  }, []);

  const handleSave = useCallback(async () => {
    await fetchPaymentMethods();
    setPaymentMethodUpdateDialogOpen(false);
  }, [fetchPaymentMethods]);

  return (
    <Container maxWidth="md">
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("payment-dialog-title")}</DialogTitle>
        <DialogContent>
          {loading && <div>Loading...</div>}
          {error && <div>Error</div>}
          <Box>
            <Typography variant="body2">{t("payment-dialog-text")}</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          {!loading && !error && data && data.length > 0 && (
            <Box border={1} borderColor="grey.300" borderRadius={4} p={2}>
              {data?.map((method: any, index: number) => (
                <Box key={method.id}>
                  {index !== 0 && <Divider />}
                  {defaultPaymentMethod !== method.id && (
                    <Typography variant="caption">
                      Something is wrong
                    </Typography>
                  )}
                  <Box my={2}>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center" gap={2}>
                        {renderCardBrand(method.card.brand)}
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Box display="flex" alignItems="center" gap={2}>
                            <Typography variant="body2">
                              {method.billing_details.name} ••••{" "}
                              {method.card.last4}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={2}>
                            <Typography variant="body2">
                              {t("payment-method-expires")}:{" "}
                              {method.card.exp_month}/{method.card.exp_year}
                            </Typography>
                            <Box ml={3}></Box>
                          </Box>
                        </Box>
                      </Box>
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        onClick={handlePaymentUpdate}
                      >
                        {t("update-payment-method-update-button")}{" "}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Elements stripe={stripePromise}>
        <PaymentMethodUpdateDialog
          open={paymentMethodUpdateDialogOpen}
          onClose={() => setPaymentMethodUpdateDialogOpen(false)}
          onSave={handleSave}
          userId={sub}
        />
      </Elements>
    </Container>
  );
};

export default BillingDialog;
