import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import App from "./components/App/App.module";
import Members from "./components/members/members.module";
import NoPage from "./NoPage";
import { SignUp } from "./components/signup/signup.module";
import Onboarding from "./components/onboarding/onboarding";
import OnboardingGuard from "./guards/onboarding-guard";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Routes key={location.pathname}>
      <Route path="/" element={<App />} />
      <Route
        path="/members"
        element={
          <OnboardingGuard>
            <Members />
          </OnboardingGuard>
        }
      >
      </Route>
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default AppRoutes;
