import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  CardActions,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import femaleSilhouette from "../images/female.png";
import maleSilhouette from "../images/male.png";
import { apiCall } from "../services/api-call.service";
import { useDispatch } from "react-redux";
import { openChat } from "../../redux/actions/chatActions";
import { updateMemberStatus } from "../../redux/actions/membersActions";

export interface IMember {
  id: string;
  name: string;
  city: string;
  age: number;
  gender: string;
  img: string | string[];
  answers: object;
  status?: string;
  distance?: number;
}

interface MemberProps {
  member: IMember;
  onClick?: () => void;
}

export const Member: React.FC<MemberProps> = ({ member, onClick, ...rest }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(member.status);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLikeClick = async (e: any) => {
    e.stopPropagation();

    const res = await apiCall("put", `connections/${member.id}`);
    if (res.success) {
      setStatus(res.status);
      dispatch(updateMemberStatus(member.id, res.status));
    }
  };

  const handleChatClick = (e: any) => {
    e.stopPropagation();
    dispatch(openChat(member.id, member.name));
  };

  let statusColor: "default" | "primary" | "secondary" | "error" = "default";
  switch (status) {
    case "like":
      statusColor = "primary";
      break;
    case "liked":
      statusColor = "secondary";
      break;
    case "mutual":
      statusColor = "error";
      break;
    default:
      statusColor = "default";
      break;
  }

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src =
      member.gender === "f" ? femaleSilhouette : maleSilhouette;
  };

  const imageSrc = `${g_baseUrl}pictures/profile/${member.id}`;

  return (
    <Card
      onClick={onClick}
      {...rest}
      sx={{
        width: "100%",
        height: "auto",
        m: 2,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        transition: "0.3s",
        "&:hover": {
          boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.2)",
          transform: "scale(1.05)",
        },
        "@media (min-width:600px)": {
          maxWidth: "140px",
        },
        "@media (min-width:900px)": {
          maxWidth: "120px",
        },
      }}
    >
      <CardMedia
        component="img"
        sx={{
          height: "54%",
          objectFit: "cover",
          objectPosition: "center",
        }}
        loading="lazy"
        image={
          imageSrc ||
          (member.gender === "f" ? femaleSilhouette : maleSilhouette)
        }
        alt="User"
        onError={handleImageError}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {member.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {member.city} Age:{member.age} Distance:{member.distance}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-around", padding: "0" }}
      >
        <IconButton
          aria-label="add to favorites"
          color={statusColor}
          onClick={handleLikeClick}
          size={isMobile ? "small" : "medium"}
        >
          <FavoriteIcon />
        </IconButton>
        <IconButton
          aria-label="chat"
          onClick={handleChatClick}
          size={isMobile ? "small" : "medium"}
        >
          <ChatBubbleIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
