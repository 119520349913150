import { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Card,
  CardMedia,
  Typography,
  Stack,
  LinearProgress,
  Icon,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Dropzone from "react-dropzone";
import { apiCall } from "../services/api-call.service";
import {
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  DeleteForever,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface ImageScrollerProps {
  pictures: any[];
  onPictureDelete: (picture: any) => void;
  onPictureSetProfile: (picture: any) => void;
  onRefresh: () => void;
}

const ImageScrollerDropzone: FC<ImageScrollerProps> = ({
  pictures,
  onRefresh,
  onPictureDelete,
  onPictureSetProfile,
}) => {
  const { t } = useTranslation("pictures-dialog");
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hoverOnPicture, setHoverOnPicture] = useState<number | null>(null);
  const [progress, setProgress] = useState(0);
  const [previews, setPreviews] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);

  const onDropFiles = (files: any) => {
    setFiles(files);
    files.forEach((file: any) => uploadFile(file));
  };

  useEffect(() => {
    setPreviews(files.map((file) => URL.createObjectURL(file)));
    return () => {
      previews.forEach((url) => URL.revokeObjectURL(url));
      setPreviews([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const pic = await apiCall("post", "pictures", {
      isPrivate: false,
      filename: file.name,
      selected: true,
    });
    // determine where to upload file to
    const res = await apiCall("get", "pictures/upload-url?id=" + pic._id);

    // setup everything for uploading
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", res.url);
    xhr.upload.onprogress = (event) => {
      const percentage = +((event.loaded / event.total) * 100).toFixed(2);
      setProgress(percentage);
    };
    // hack to get it to upload without the mime part boundaries
    const _send = xhr.send;
    xhr.send = () => {
      _send.call(xhr, file);
    };
    xhr.onreadystatechange = async () => {
      if (xhr.readyState !== 4) return;
      if (xhr.status < 200 || xhr.status > 299) {
        console.log("ERROR:", xhr.statusText);
        console.log("REVERTING CHANGES - DELETING PICTURE", pic._id);
        await apiCall("delete", `pictures/${pic._id}`);
      } else {
        console.log("Success!");
        onRefresh();
        setPreviews([]);
        setFiles([]);
        setProgress(0);
      }
    };

    // do the deed
    xhr.send(formData);
  };

  const scroll = (scrollOffset: number) => {
    // Directly manipulate the scrollLeft property of the current ref if it's not null
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <Box display="flex" alignItems="center" maxWidth="100%">
      <IconButton onClick={() => scroll(-200)}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexGrow={1}
        overflow="auto"
        ref={scrollRef}
        gap={2}
        sx={{
          scrollbarWidth: "none", // For Firefox
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Opera
          },
          flexWrap: "nowrap", // Prevent cards from wrapping
        }}
      >
        <Box>
          {!previews.length ? (
            <Dropzone onDrop={onDropFiles}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  height={300}
                  width={225}
                  {...getRootProps()}
                  sx={{
                    border: "1px dashed grey",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    borderRadius: (theme) => theme.shape.borderRadius - 2,
                  }}
                >
                  <input {...getInputProps()} />
                  <CloudUpload sx={{ fontSize: 50, color: "action.active" }} />
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {t("drag-n-drop-files")}
                  </Typography>
                </Box>
              )}
            </Dropzone>
          ) : (
            <Box height={300} minWidth={"175px"}>
              {previews.map((preview, index) => (
                <Stack key={index} spacing={1}>
                  <Card
                    sx={{ minWidth: "fit-content", height: 285, flexGrow: 1 }}
                  >
                    <CardMedia
                      component="img"
                      image={preview}
                      sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                    />
                  </Card>
                  <LinearProgress
                    style={{ height: "15px", width: "100%" }}
                    variant="determinate"
                    value={progress}
                  />
                </Stack>
              ))}
            </Box>
          )}
        </Box>
        {pictures?.map((picture, index) => (
          <Card
            key={index}
            sx={{
              minWidth: "fit-content",
              height: 300,
              position: "relative",
            }}
            onMouseEnter={() => setHoverOnPicture(index)}
            onMouseLeave={() => setHoverOnPicture(null)}
          >
            {picture._doc.selected ? (
              <Box
                position={"absolute"}
                top={0}
                left={0}
                p={1}
                width={"50%"}
                height="100%"
              >
                <Icon>
                  <CheckCircle color="success" />
                </Icon>
              </Box>
            ) : (
              <Box
                position={"absolute"}
                top={0}
                left={0}
                p={1}
                width={"50%"}
                height="100%"
                zIndex={2}
              >
                <Tooltip
                  title={t("set-as-profile-picture")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={() => onPictureSetProfile(picture._doc._id)}
                  >
                    <CheckCircleOutline sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {hoverOnPicture === index && !picture._doc.selected && (
              <Box
                position={"absolute"}
                top={0}
                right={0}
                p={1}
                width={"50%"}
                height="100%"
              >
                <Stack height={"100%"} position={"relative"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      zIndex: 1,
                    }}
                  >
                    <IconButton
                      onClick={() => onPictureDelete(picture?._doc?._id)}
                      color="error"
                      size="small"
                      disableRipple
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                      }}
                    >
                      <DeleteForever />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            )}
            <CardMedia
              loading="lazy"
              component="img"
              image={`${picture.url}`}
              alt={picture.filename}
              sx={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </Card>
        ))}
      </Box>
      <IconButton onClick={() => scroll(200)}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default ImageScrollerDropzone;
