// messageTypes.ts
export interface Message {
  toId: string;
  fromId: string;
  type: MessageType;
  timestamp: Date;
  data?: string;
}

export enum MessageType {
  MESSAGE,
  TYPING,
  ONLINE,
  CONNECTION,
}
