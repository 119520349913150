import { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

export const YesNoQuestion = ({ saveAnswer, code, answer, questions }: any) => {
  const [myAnswer, setMyAnswer] = useState(answer || '');
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setMyAnswer(answer);
  }, [answer]);

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const a = event.target.value;

    setIsBusy(true);
    saveAnswer(code, a, () => {
      setMyAnswer(a);
      setIsBusy(false);
    });
  };

  return (
    <FormControl component="fieldset" disabled={isBusy}>
      <Typography variant="subtitle1">{questions[code]}</Typography>
      <RadioGroup row name={code} value={myAnswer} onChange={onSelect}>
        <FormControlLabel
          value="y"
          control={<Radio />}
          label="Yes"
          disabled={isBusy}
        />
        <FormControlLabel
          value="n"
          control={<Radio />}
          label="No"
          disabled={isBusy}
        />
      </RadioGroup>
    </FormControl>
  );
};
