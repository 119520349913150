import React, { useEffect, useRef, useState } from "react";
import { RootState } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { fetchMembersByStatus } from "../../../redux/actions/membersActions";
import { IMember, Member } from "../../member/member.module";
import { Box, Grid, Snackbar, SnackbarContent } from "@mui/material";
import MemberPreviewDialog from "../../member-preview-dialog/member-preview-dialog.module";

interface AllMembersProps {
  currentTab: number;
}

const AllMembers: React.FC<AllMembersProps> = ({ currentTab }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState<number>(44);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [previewMember, setPreviewMember] = useState<IMember | null>(null);
  const [reachedBottom, setReachedBottom] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const filters = useSelector((state: RootState) => state.members.filters);
  const [shouldApplyFilters, setShouldApplyFilters] = useState<boolean>(
    filters.shouldApplyFilters
  );
  // convert tab number to tab name
  const tabName = ["all", "liked", "like", "mutual"][currentTab];
  // determine which users list to show
  let membersList: IMember[] = useSelector((state: RootState) => {
    switch (currentTab) {
      case 0:
        return state.members.allMembers;
      case 1:
        return state.members.likedMembers;
      case 2:
        return state.members.givenLikes;
      case 3:
        return state.members.mutualMembers;
      default:
        return [];
    }
  });

  const totalUsers = membersList.length;

  // if filter is enabled
  if (shouldApplyFilters) {
    // filter out list based on age and distance
    membersList = membersList.filter((member: IMember) => {
      if (!filters) {
        return true;
      }

      const { ageRange, miles } = filters;
      const isAgeInRange =
        !ageRange || (member.age >= ageRange[0] && member.age <= ageRange[1]);
      const isWithinMiles =
        !miles || (member.distance !== undefined && member.distance <= miles);

      return isAgeInRange && isWithinMiles;
    });
  }

  const prevFilters = useRef(filters);
  const displayedUsers = membersList.length;
  // determine if users have been filtered out
  const hiddenUsers = totalUsers - displayedUsers;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const loadMoreMembers = () => {
    setPageSize((prevPageSize) => prevPageSize + 45);
    setCurrentPage(1);
    setReachedBottom(true);

    dispatch<any>(fetchMembersByStatus(tabName, pageSize + 25, currentPage));
  };

  useEffect(() => {
    setShouldApplyFilters(filters.shouldApplyFilters);
  }, [filters.shouldApplyFilters]);

  useEffect(() => {
    dispatch<any>(fetchMembersByStatus(tabName, pageSize, currentPage));
  }, [currentPage, dispatch, pageSize, tabName]);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      const { scrollTop, clientHeight, scrollHeight } =
        e.currentTarget as HTMLDivElement;

      const scrolledNearBottom =
        scrollTop + clientHeight >= scrollHeight - clientHeight / 2;

      if (scrolledNearBottom && !reachedBottom) {
        loadMoreMembers();
      } else if (!scrolledNearBottom) {
        setReachedBottom(false);
      }
    };

    const handleResize = () => {
      loadMoreMembers();
    };

    const handleInitialLoad = () => {
      const scrollContainer = scrollContainerRef.current;

      if (scrollContainer) {
        const { clientHeight, scrollHeight } = scrollContainer;
        const scrolledNearBottom =
          clientHeight >= scrollHeight - clientHeight / 2;

        if (scrolledNearBottom && !reachedBottom) {
          loadMoreMembers();
        }
      }
    };

    handleInitialLoad();

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reachedBottom, setPageSize, currentTab, pageSize, currentPage, dispatch]);

  useEffect(() => {
    if (prevFilters.current !== filters) {
      prevFilters.current = filters;
      setShouldApplyFilters(filters.shouldApplyFilters);
      loadMoreMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (hiddenUsers > 0) {
      handleSnackbarOpen();
    }
  }, [hiddenUsers]);

  const handleMemberPreview = (id: string) => {
    setPreviewMember(membersList.find((member) => member.id === id) || null);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  // Función para cerrar el Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        ref={scrollContainerRef}
        sx={{
          height: "100vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.2rem",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Grid container spacing={2}>
          {membersList.map((member: IMember) => (
            <Grid item key={member.id}>
              <Member
                member={member}
                onClick={() => handleMemberPreview(member.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <MemberPreviewDialog
        onClose={() => setPreviewMember(null)}
        open={!!previewMember}
        id={previewMember?.id || ""}
      />
      {shouldApplyFilters && hiddenUsers > 0 && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: "9999",
          }}
        >
          <SnackbarContent
            style={{
              backgroundColor: "blue",
              color: "white",
              fontSize: "1.5rem",
            }}
            message={`Filtered out users: ${hiddenUsers}`}
          />
        </Snackbar>
      )}
    </>
  );
};

export default AllMembers;
