import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import s from "../questions-dialog.module.scss";

export const MultiQuestion = ({ saveAnswer, code, answer, questions }: any) => {
  const [myAnswer, setMyAnswer] = useState(answer || []);
  const answers = questions[code]?.o;
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setMyAnswer(answer);
  }, [answer]);

  const onSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const targetId = event.target.name;
    let newAnswer: any;

    if (!checked) {
      newAnswer = myAnswer.filter((a: any) => a !== targetId);
    } else if (!myAnswer.includes(targetId)) {
      newAnswer = [...myAnswer, targetId];
    }
    setMyAnswer(newAnswer);

    setIsBusy(true);
    saveAnswer(code, newAnswer.length > 0 ? newAnswer.join(",") : "-", () => {
      setIsBusy(false);
    });
  };

  return (
    <div className={s.answerBlock}>
      <FormControl component="fieldset" disabled={isBusy}>
        {/* <Typography variant="subtitle1">{questions[code]}</Typography> */}
        <FormGroup>
          {Object.keys(answers).map((key, i) => {
            const answerStr = answers[key];
            return (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    onChange={onSelect}
                    name={key}
                    checked={myAnswer.includes(key)}
                    disabled={isBusy}
                  />
                }
                label={answerStr}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
};
