import Cookies from "universal-cookie";

export function apiCall(method: string, url: string, body?: any, options?: any): Promise<any> {
  const cookies = new Cookies();
  const accessToken = cookies.get("at");

  return fetch(g_baseUrl + url, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
    ...options,
  }).then(async (res) => {
    if (res.status === 204) {
      return null;
    }

    if (!res.ok) {
      const errorText = await res.text();
      console.error(`API Error: ${res.status} - ${errorText}`);
      console.error("API Error details:", res);
      throw new Error(`API Error: ${res.status} - ${errorText}`);
    }

    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return res.json();
    } else {
      return res.text();
    }
  });
}


export const setToSessionStorage = (key: string, data: any) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export function getFromSessionStorage(key: string): any {
  // const data = sessionStorage.getItem(key);
  return null; // data ? JSON.parse(data) : null;
}
