import jwtDecode from "jwt-decode";
import { FC, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Box, Typography } from "@mui/material";

interface OnboardingGuardProps {
  children: React.ReactNode;
}

const OnboardingGuard: FC<OnboardingGuardProps> = ({ children }) => {
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("at");
    if (!token) {
      window.location.href = "/";
      return;
    }
    const fetchUser = async () => {
      const { onboarding } = jwtDecode(token as string) as any;
      if (onboarding) {
        window.location.href = "/onboarding";
        return;
      }
      setInitializing(false);
    };
    fetchUser();
  }, []);
  return (
    <Box>
      {initializing ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100vw"}
          height={"100vh"}
        >
          <Typography variant="h4" component="div" gutterBottom>
            Loading...
          </Typography>
        </Box>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};

export default OnboardingGuard;
