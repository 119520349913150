import { MultiQuestion } from "./multi-question/multi-question.module";
import { NumberQuestion } from "./number-question/number-question.module";
import { SingleQuestion } from "./single-question/single-question.module";
import { TextQuestion } from "./text-question/text-question.module";
import { YesNoQuestion } from "./yesno-question/yesno-question.module";
import s from "./questions-dialog.module.scss";
import { apiCall } from "../services/api-call.service";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IQuestion {
  q: string;
  a: string[];
}

interface IQuestionListProps {
  questCodes: string[];
  questions: { [code: string]: IQuestion };
  answers: { [code: string]: any } | null;
  saveAnswer: (code: string, answer: any, callback: any) => void;
}

const QuestionList: FC<IQuestionListProps> = ({
  questCodes,
  questions,
  answers,
  saveAnswer,
}) => {
  return (
    <>
      {questCodes.map((code, i) => {
        const questText: string = questions[code]?.q || "";
        const myAnswer = answers ? answers[code] : {};
        let answ = null;
        switch (code[0]) {
          case "n": // number
            answ = (
              <NumberQuestion
                saveAnswer={saveAnswer}
                code={code}
                answer={myAnswer}
                questions={questions}
              />
            );
            break;
          case "r": // radio (single-select)
            answ = (
              <SingleQuestion
                saveAnswer={saveAnswer}
                code={code}
                answer={myAnswer}
                questions={questions}
              />
            );
            break;
          case "y": // yes/no (boolean)
            answ = (
              <YesNoQuestion
                saveAnswer={saveAnswer}
                code={code}
                answer={myAnswer}
                questions={questions}
              />
            );
            break;
          case "c": // combo (multi-select)
            answ = (
              <MultiQuestion
                saveAnswer={saveAnswer}
                code={code}
                answer={myAnswer || []}
                questions={questions}
              />
            );
            break;
          case "t": // text
            answ = (
              <TextQuestion
                saveAnswer={saveAnswer}
                code={code}
                answer={myAnswer || ""}
                questions={questions}
              />
            );
            break;
          default:
            answ = <div>Invalid code {code}</div>;
            break;
        }

        return (
          <div key={i} className={s.questionAnswer}>
            <div className={s.question}>{questText}</div>
            {answ}
          </div>
        );
      })}
    </>
  );
};

export const QuestionsDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation("questions-dialog");
  const [loading, setLoading] = useState(true);
  const [questCodes, setQuestCodes] = useState<string[]>([]);
  const [questions, setQuestions] = useState<{ [code: string]: IQuestion }>({});
  const [answers, setAnswers] = useState<{ [code: string]: any }>({});

  useEffect(() => {
    apiCall("get", `questions/all/${g_lang}`).then((res) => {
      const questCodes = Object.keys(res);
      setQuestCodes(questCodes);

      // Set questions as an object with code as keys and IQuestion as values
      const questionsObject: { [code: string]: IQuestion } = {};
      questCodes.forEach((code) => {
        questionsObject[code] = res[code];
      });
      setQuestions(questionsObject);

      setLoading(false);
    });
    apiCall("get", `questions/answers`).then((res) => {
      setAnswers(res);
    });
  }, []);

  const saveAnswer = (code: string, answer: any, callback: any = null) => {
    apiCall("put", `questions/answer/${code}/${answer}`).then((res) => {
      callback(res);
    });
  };

  const handleDialogClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{t("questions")}</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleDialogClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{t("context")}</DialogContentText>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {!questCodes.length ? (
              <div>No questions</div>
            ) : (
              <QuestionList
                questCodes={questCodes}
                questions={questions}
                answers={answers}
                saveAnswer={saveAnswer}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};
