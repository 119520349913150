import { useCallback, useEffect, useState } from "react";
import { apiCall } from "../../services/api-call.service";

const usePictures = () => {
  const [pictures, setPictures] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const getPictures = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiCall("GET", "pictures");
      setPictures(response.data.sort((a: any, b: any) => (b?._doc?.selected ? 1 : 0) - (a?._doc?.selected ? 1 : 0)));      setLoading(false);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getPictures();
  }, [getPictures]);

  const setPicturesData = useCallback(
    (pictures: any[]) => {
      setPictures(pictures);
    },
    []
  );

  return {
    pictures,
    loading,
    error,
    getPictures,
    setPicturesData,
  };
};

export default usePictures;
