import { io } from 'socket.io-client';
import Cookies from 'universal-cookie';

export function getSocket() {
  const cookies = new Cookies();
  const accessToken = cookies.get('at');

  const options = {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    },
  };
  const url = window.location.hostname.endsWith('susurro.com')
    ? `https://chat.susurro.com?uid=${g_user.id}` // production server
    : `http://localhost:3001?uid=${g_user.id}`; // local dev server
  return io(url, options);
}
