import { FC, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { apiCall } from "../services/api-call.service";

interface PaymentMethodUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  userId: string;
}

const cardStyle = {
  style: {
    base: {
      width: "400px",
      color: "#32325d",
      fontFamily: '"Roboto", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  disableLink: true,
};

const PaymentMethodUpdateDialog: FC<PaymentMethodUpdateDialogProps> = ({
  open,
  onClose,
  onSave,
  userId,
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation("payment-method-form");
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSaving(true);
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const customer = await apiCall("POST", "stripe/customers");
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement as any,
          billing_details: {
            name: customer.name,
          },
        })
        .then((result) => {
          if (result.paymentMethod) {
            apiCall("POST", `stripe/customers/payment-methods/attach`, {
              paymentMethodId: result.paymentMethod.id,
            }).then((result) => {
              apiCall("PUT", `users/${userId}`, {
                stripeCustomerId: customer.id,
              }).then((result) => {
                onSave?.();
                setSaving(false);
              });
            });
          }
        })
        .catch((error) => {
          console.info(error);
          setSaving(false);
        });
    } catch (error) {
      console.info(error);
      setSaving(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("update-payment-method-title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          {t("update-payment-method-text")}
        </Typography>
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
          <form onSubmit={handleSubmit} style={{ width: "80%" }}>
            <Divider sx={{ my: 2 }} />
            <CardElement
              options={{
                ...cardStyle,
              }}
            />

            <Box display={"flex"} justifyContent={"space-between"} mt={5}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={!elements || saving}
                size="small"
              >
                {saving ? t("update-payment-method-saving") : t("update-payment-method-submit-button")}
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("update-payment-method-cancel-button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentMethodUpdateDialog;
