import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import { FC, useCallback } from "react";
import { apiCall } from "../services/api-call.service";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import usePictures from "./hooks/usePictures";
import ImageScrollerDropzone from "./picture-carousel.module-dz";

interface IPicturesDialogProps {
  onClose: any;
  open: boolean;
}

export const PicturesDialog: FC<IPicturesDialogProps> = (props) => {
  const { t } = useTranslation("pictures-dialog");
  const { onClose, open } = props;
  const { pictures, getPictures } = usePictures();

  const handleDialogClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSelect = useCallback(
    async (id: string) => {
      const response = await apiCall("put", `pictures/${id}/selected`);
      if (response.error) {
        console.error(response.error);
        return;
      }
      getPictures();
    },
    [getPictures]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      console.log("delete picture", id);
      const response = await apiCall("delete", `pictures/${id}`);
      if (response.error) {
        console.error(response.error);
        return;
      }
      getPictures();
    },
    [getPictures]
  );

  return (
    <>
      <Dialog
        onClose={handleDialogClose}
        open={open}
        aria-labelledby="dialog-title"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{t("my-pictures")}</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleDialogClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: 400,
            width: 550,
          }}
        >
          <ImageScrollerDropzone
            pictures={pictures}
            onPictureDelete={handleDelete}
            onPictureSetProfile={handleSelect}
            onRefresh={() => getPictures()}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
