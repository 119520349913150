import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  CssBaseline,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import s from "./members.module.scss";
import { getSocket } from "../../socket";
import { RootState } from "../../redux/store";
import {
  closeChat,
  toggleMinimizeChat,
  receiveMessage,
  openChat,
  setUserOnline,
  setUserOffline,
} from "../../redux/actions/chatActions";
import { QuestionsDialog } from "../questions/questions-dialog.module";
import { PicturesDialog } from "../pictures-dialog/pictures-dialog.module";
import MaterialTabs from "./tabs/MaterialTabs";
import AllMembers from "./memberList/membersList";
import ChatWindow from "../chat/chat-window.module";
import { Socket } from "socket.io-client";
import { Message } from "../../redux/messageTypes";
import { IMember } from "../member/member.module";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import BillingDialog from "../billing-dialog/billing-dialog.module";
import Filters from "./filters/filters";
import TuneIcon from "@mui/icons-material/Tune";

declare const g_baseUrl: string;

interface ChatState {
  [key: string]: {
    userName: string;
    isMinimized: boolean;
  };
}

export const Members = () => {
  const mutualMembers = useSelector(
    (state: RootState) => state.members.mutualMembers
  );
  const filtersApplied = useSelector(
    (state: RootState) => state.members.shouldApplyFilters
  );
  const [secondNavbarOpen, setSecondNavbarOpen] = useState<boolean>(false);
  const chats = useSelector((state: RootState) => state.chat.chats);
  const [questionsVisible, setQuestionsVisible] = useState(false);
  const [picturesVisible, setPicturesVisible] = useState(false);
  const [billingVisible, setBillingVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const chatSocket = useRef<Socket | null>(null);
  const { t } = useTranslation("members");
  const dispatch = useDispatch();
  const currentUserName = g_user.email;
  const iconColor = filtersApplied ? "#29b304" : "black";

  if (!chatSocket.current) {
    chatSocket.current = getSocket();
  }

  useEffect(() => {
    const savedChats = localStorage.getItem("chats");
    if (savedChats) {
      const parsedChats = JSON.parse(savedChats) as ChatState;
      Object.entries(parsedChats).forEach(([chatId, chat]) => {
        dispatch(openChat(chatId, chat.userName));
        if (chat.isMinimized) {
          dispatch(toggleMinimizeChat(chatId));
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  useEffect(() => {
    const socket = chatSocket.current;
    const userActivityTimers: Record<string, NodeJS.Timeout> = {};
    if (socket) {
      const onReceiveMessage = (message: Message) => {
        if (!message.fromId || !message.toId) {
          console.error("Message received with missing IDs", message);
          return;
        }

        const chatId =
          message.fromId === g_user.id ? message.toId : message.fromId;

        let userName = mutualMembers.find(
          (member: IMember) => member.id === chatId
        )?.name;
        userName =
          userName ||
          getMemberNameById(message.fromId) ||
          "Usuario Desconocido";

        if (!chats[chatId]) {
          dispatch(openChat(chatId, userName));
        }
        dispatch(receiveMessage(message, g_user.id));

        if (message.fromId !== g_user.id) {
          dispatch(setUserOnline(message.fromId));
        }

        const timerId = userActivityTimers[message.fromId];
        if (timerId) {
          clearTimeout(timerId);
        }

        userActivityTimers[message.fromId] = setTimeout(() => {
          dispatch(setUserOffline(message.fromId));
          delete userActivityTimers[message.fromId];
        }, 300000);
      };

      socket.on("message", onReceiveMessage);

      return () => {
        socket.off("message", onReceiveMessage);
        Object.values(userActivityTimers).forEach((timer) => {
          if (typeof timer === "number") {
            clearTimeout(timer);
          }
        });
      };
    }
  }, [chats, dispatch, mutualMembers]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  const filtersElement = (
    <Filters
      onClose={() => setSecondNavbarOpen(false)}
      open={secondNavbarOpen}
    />
  );

  function getMemberNameById(fromId: string): string | null {
    const allMembersString = sessionStorage.getItem("allMembers");
    if (allMembersString === null) {
      return null;
    }

    const allMembers = JSON.parse(allMembersString) as Array<{
      id: string;
      name: string;
    }>;
    const member = allMembers.find(
      (member: { id: string; name: string }) => member.id === fromId
    );
    return member ? member.name : null;
  }

  const closeChatAction = (chatId: string) => {
    dispatch(closeChat(chatId));
  };

  const toggleMinimizeAction = (chatId: string) => {
    dispatch(toggleMinimizeChat(chatId));
  };

  const doLogout = () => {
    const cookies = new Cookies();
    const accessToken = cookies.get("at");
    fetch(g_baseUrl + "auth/logout", {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(() => {
      cookies.remove("at");
      window.location.href = "/";
      if (chatSocket.current) {
        chatSocket.current.close();
      }
    });
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          t("questions-option"),
          t("pictures-option"),
          t("billing-option"),
          t("logout-option"),
        ].map((text, index) => (
          <ListItem button key={text} onClick={() => handleMenuClick(index)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {/* Aquí puedes agregar más secciones al menú si es necesario */}
    </div>
  );

  const handleMenuClick = (index: number) => {
    switch (index) {
      case 0:
        setQuestionsVisible(true);
        break;
      case 1:
        setPicturesVisible(true);
        break;
      case 2:
        setBillingVisible(true);
        break;
      case 3:
        doLogout();
        break;
      default:
        alert("Unknown menu option: " + index);
    }
  };

  if (loading) {
    return (
      <div className={s.center}>
        <div className={s.loader}>
          <div className={s.container}>
            <div className={s.carousel}>
              <div className={s.love}></div>
              <div className={s.love}></div>
              <div className={s.love}></div>
              <div className={s.love}></div>
              <div className={s.love}></div>
              <div className={s.love}></div>
              <div className={s.love}></div>
            </div>
          </div>
          <div className={s.container}>
            <div className={s.carousel}>
              <div className={s.death}></div>
              <div className={s.death}></div>
              <div className={s.death}></div>
              <div className={s.death}></div>
              <div className={s.death}></div>
              <div className={s.death}></div>
              <div className={s.death}></div>
            </div>
          </div>
          <div className={s.container}>
            <div className={s.carousel}>
              <div className={s.robots}></div>
              <div className={s.robots}></div>
              <div className={s.robots}></div>
              <div className={s.robots}></div>
              <div className={s.robots}></div>
              <div className={s.robots}></div>
              <div className={s.robots}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handlePictureDialogClose = () => {
    setPicturesVisible(false);
  };

  const toggleFiltersIcon = () => {
    setSecondNavbarOpen(!secondNavbarOpen);
  };

  return (
    <Box className={s.container} minHeight="100vh">
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <MaterialTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <Typography
            variant="body1"
            color="inherit"
            style={{ marginLeft: "auto" }}
          >
            {currentUserName}
          </Typography>
          <div style={{ marginRight: "8px", marginLeft: "13px" }}>
            <Avatar alt={currentUserName} src={``} />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      <IconButton
        id="filters-icon"
        edge="end"
        color="inherit"
        aria-label="filters"
        onClick={toggleFiltersIcon}
        sx={{
          position: "fixed",
          right: 10,
          top: secondNavbarOpen ? "10px" : "calc(10px + 56px)",
          zIndex: 1001,
          fontSize: secondNavbarOpen ? "0px !important" : "48px !important",
          color: iconColor,
          transition: "font-size 0.3s ease",
          visibility: secondNavbarOpen ? "hidden" : "visible",
          border: "3px solid black",
          borderRadius: "50%",
          "&:hover": {
            fontSize: secondNavbarOpen ? "0px !important" : "65px !important",
          },
        }}
      >
        <TuneIcon sx={{ fontSize: "inherit !important" }} />
      </IconButton>
      {filtersElement}
      <Box style={{ marginTop: secondNavbarOpen ? "180px" : "0" }}>
        <AllMembers currentTab={currentTab} />
      </Box>
      <Box className={s.chatContainerWrapper}>
        {chatSocket.current &&
          Object.entries(chats).map(([chatId, chat]) => (
            <ChatWindow
              key={chatId}
              socket={chatSocket.current || null}
              userId={chatId}
              userName={chat.userName}
              closeChat={() => closeChatAction(chatId)}
              isMinimized={chat.isMinimized}
              toggleMinimize={() => toggleMinimizeAction(chatId)}
              id={g_user.id}
            />
          ))}
      </Box>
      {questionsVisible && (
        <QuestionsDialog onClose={() => setQuestionsVisible(false)} />
      )}
      <PicturesDialog
        onClose={handlePictureDialogClose}
        open={picturesVisible}
      />
      <BillingDialog
        onClose={() => setBillingVisible(false)}
        open={billingVisible}
      />
    </Box>
  );
};

export default Members;
