import { IMember } from "../../components/member/member.module";
import {
  SET_ALL_MEMBERS,
  SET_LIKED_MEMBERS,
  SET_MUTUAL_MEMBERS,
  FETCH_MEMBERS_FAILED,
  SET_GIVEN_LIKES,
  UPDATE_MEMBER_STATUS,
  SET_FILTERS,
} from "../actions/membersActions";

interface MemberState {
  allMembers: IMember[];
  likedMembers: IMember[];
  mutualMembers: IMember[];
  givenLikes: IMember[];
  error: string | null;
  filters: { ageRange: number[]; miles: number };
  shouldApplyFilters: boolean,
}

interface MemberAction {
  type: string;
  payload: any;
}

const initialState = {
  allMembers: [],
  likedMembers: [],
  mutualMembers: [],
  givenLikes: [],
  error: null,
  filters: { ageRange: [18, 100], miles: 20 },
  shouldApplyFilters: false,
};


const membersReducer = (
  state: MemberState = initialState,
  action: MemberAction
) => {
  switch (action.type) {
    case SET_ALL_MEMBERS:
      return { ...state, allMembers: action.payload };
    case SET_LIKED_MEMBERS:
      return { ...state, likedMembers: action.payload };
    case SET_MUTUAL_MEMBERS:
      return { ...state, mutualMembers: action.payload };
    case FETCH_MEMBERS_FAILED:
      return { ...state, error: action.payload };
    case SET_GIVEN_LIKES:
      return { ...state, givenLikes: action.payload };
    case UPDATE_MEMBER_STATUS:
      return {
        ...state,
        allMembers: state.allMembers.map((member) =>
          member.id === action.payload.memberId
            ? { ...member, status: action.payload.status }
            : member
        ),
      };
    case SET_FILTERS:
      const { shouldApplyFilters } = action.payload;
      return {
        ...state,
        filters: { ...action.payload },
        shouldApplyFilters,
      };
    default:
      return state;
  }
};

export default membersReducer;
