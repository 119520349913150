// @ts-ignore
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "font-awesome/css/font-awesome.min.css";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./AppRoutes";
import { Provider } from "react-redux";
import store from "./redux/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./i18n-config";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

// Asegúrate de tener las declaraciones globales correctas
declare global {
  interface Window {
    g_langQuestions?: any;
    g_answers?: any;
    g_lang?: string;
    g_baseUrl?: string;
    g_user?: {
      id: string;
      age: number;
      email: string;
    };
  }
}

// Inicializa las propiedades globales
window.g_langQuestions = {};
window.g_answers = {};
window.g_lang = window.localStorage.i18nextLng || "defaultLang";
if (typeof window.g_lang === "string" && window.g_lang.indexOf("-") !== -1) {
  window.g_lang = window.g_lang.split("-")[0];
}
window.g_baseUrl = window.location.hostname.endsWith("susurro.com")
  ? "https://rest.susurro.com/api/v1/"
  : "http://localhost:3000/api/v1/";

// Asegúrate de tener las declaraciones correctas
const cookies = Cookies;
const accessToken = cookies.get("at");
if (accessToken) {
  const decodedToken = accessToken ? jwt_decode<JwtPayload>(accessToken) : null;
  if (decodedToken) {
    window.g_user = {
      id: decodedToken.sub || "",
      email: decodedToken.email || "",
      age: decodedToken.age || 0,
    };
  } else {
    console.error("Token is undefined or invalid.");
  }
} else {
  window.g_user = {
    id: "",
    email: "",
    age: 0,
  };
}

ReactDOM.render(
  <Suspense fallback="Loading...">
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <AppRoutes />
          <ToastContainer
            position="bottom-left"
            style={{ bottom: "0", left: "0", top: "auto", right: "auto" }}
          />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
