import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  apiCall,
  setToSessionStorage,
} from "../../components/services/api-call.service";
import { Message } from "../messageTypes";
import { RootState } from "../store";

export const OPEN_CHAT = "OPEN_CHAT";
export const CLOSE_CHAT = "CLOSE_CHAT";
export const TOGGLE_MINIMIZE_CHAT = "TOGGLE_MINIMIZE_CHAT";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const FETCH_ONLINE_FRIENDS = "FETCH_ONLINE_FRIENDS";
export const FETCH_ONLINE_FRIENDS_SUCCESS = "FETCH_ONLINE_FRIENDS_SUCCESS";
export const FETCH_ONLINE_FRIENDS_FAILURE = "FETCH_ONLINE_FRIENDS_FAILURE";
export const FETCH_CONVERSATION = "FETCH_CONVERSATION";
export const FETCH_CONVERSATION_SUCCESS = "FETCH_CONVERSATION_SUCCESS";
export const FETCH_CONVERSATION_FAILURE = "FETCH_CONVERSATION_FAILURE";
export const SET_USER_ONLINE = "SET_USER_ONLINE";
export const SET_USER_OFFLINE = "SET_USER_OFFLINE";
export const FETCH_CHAT_MESSAGES = "FETCH_CHAT_MESSAGES";
export const FETCH_CHAT_MESSAGES_FAILED = "FETCH_CHAT_MESSAGES_FAILED";

export const openChat = (chatId: string, userName: string) => ({
  type: OPEN_CHAT,
  payload: { chatId, userName },
});

export const closeChat = (chatId: string) => ({
  type: CLOSE_CHAT,
  payload: chatId,
});

export const toggleMinimizeChat = (chatId: string) => {
  return {
    type: TOGGLE_MINIMIZE_CHAT,
    payload: chatId,
  };
};

export const addMessage = (chatId: string, message: Message) => ({
  type: ADD_MESSAGE,
  payload: { chatId, message },
});

export const sendMessage = (message: Message) => ({
  type: SEND_MESSAGE,
  payload: message,
});

export const receiveMessage = (message: Message, userId: string) => ({
  type: RECEIVE_MESSAGE,
  payload: { message, userId },
});

export const setUserOnline = (userId: string) => {
  return {
    type: "SET_USER_ONLINE",
    payload: userId,
  };
};

export const setUserOffline = (userId: string) => ({
  type: "SET_USER_OFFLINE",
  payload: userId,
});

export const fetchOnlineFriends =
  () => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      const response = await apiCall("GET", "/api/v1/chat/online");
      dispatch({ type: FETCH_ONLINE_FRIENDS_SUCCESS, payload: response.data });
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch({
          type: FETCH_ONLINE_FRIENDS_FAILURE,
          payload: error.message,
        });
      }
    }
  };

export const fetchChatMessages = (
  userId: string,
  offset: number,
  count: number
): ThunkAction<void, RootState, unknown, Action<string>> => {
  return async (dispatch, getState) => {
    try {
      // Verificar si hay mensajes en el almacenamiento de sesión
      const storedData = sessionStorage.getItem(`${userId}_chatMessages`);
      let decodedData: any[] = [];
      if (storedData) {
        decodedData = storedData ? JSON.parse(storedData) : [];
      }

      // Combinar mensajes existentes con nuevos mensajes si los hay
      const existingMessages = decodedData || [];
      const newMessages = await apiCall(
        "GET",
        `mesgs/${userId}?offset=${offset}&limit=${count}`
      );
      const allMessages = existingMessages.concat(newMessages);

      // Ordenar los mensajes por timestamp
      const sortedMessages = allMessages.sort(
        (a: any, b: any) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );

      // Filtrar y eliminar mensajes duplicados
      const uniqueMessages = sortedMessages.filter(
        (message, index, self) =>
          index ===
          self.findIndex(
            (m) =>
              new Date(m.timestamp).getTime() ===
              new Date(message.timestamp).getTime()
          )
      );

      // Actualizar el almacenamiento de sesión
      setToSessionStorage(`${userId}_chatMessages`, uniqueMessages);

      dispatch({
        type: FETCH_CHAT_MESSAGES,
        payload: {
          userId,
          messages: uniqueMessages,
        },
      });
    } catch (error: any) {
      dispatch({
        type: FETCH_CHAT_MESSAGES_FAILED,
        payload: error.message,
      });
    }
  };
};

export interface FetchChatMessagesAction {
  type: typeof FETCH_CHAT_MESSAGES;
  payload: { userId: string; messages: Message[]; countNextMessage: number };
}

export interface FetchChatMessagesFailedAction {
  type: typeof FETCH_CHAT_MESSAGES_FAILED;
  payload: string;
}

interface OpenChatAction {
  type: typeof OPEN_CHAT;
  payload: { chatId: string; userName: string };
}

interface CloseChatAction {
  type: typeof CLOSE_CHAT;
  payload: string;
}

interface ToggleMinimizeChatAction {
  type: typeof TOGGLE_MINIMIZE_CHAT;
  payload: string;
}

interface AddMessageAction {
  type: typeof ADD_MESSAGE;
  payload: { chatId: string; message: Message };
}

interface ReceiveMessageAction {
  type: typeof RECEIVE_MESSAGE;
  payload: { message: Message; userId: string };
}
interface SetUserOnline {
  type: typeof SET_USER_ONLINE;
  payload: string;
}

interface SetUserOffline {
  type: typeof SET_USER_OFFLINE;
  payload: string;
}

export type ChatActionTypes =
  | OpenChatAction
  | CloseChatAction
  | ToggleMinimizeChatAction
  | AddMessageAction
  | ReceiveMessageAction
  | SetUserOnline
  | SetUserOffline
  | FetchChatMessagesAction
  | FetchChatMessagesFailedAction;
